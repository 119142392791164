import { SSLayout } from '@/src/layout/superstack/SSLayout';
import { useRouter } from 'next/router';
import styles from '@/styles/superstack/error.module.scss';
import classNames from 'classnames/bind';
import Img404 from '@/asset/superstack/svgs/img-404.svg';
import { Button } from '@/src/component/superstack/base/Button';
import { GeneralLinks } from '@/data/superstack/GeneralLinks';

const Page404 = () => {
  const router = useRouter();
  const cx = classNames.bind(styles);
  return (
    <SSLayout
      pageTitle={'페이지를 찾을 수 없습니다.'}
      width={'1080px'}
      withContactInfoModal={false}
      withEmailAuthRequiredModal={false}
    >
      <section className={cx('content-wrapper')}>
        <Img404 width={360} height={240} />
        <div className={cx('content')}>
          <div className={cx('title')}>페이지를 찾을 수 없습니다.</div>
          <div className={cx('description')}>
            <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.</p>
            <p>입력하신 주소가 정확한지 다시 한번 확인해 주세요.</p>
          </div>
          <div className={cx('btn-wrapper')}>
            <Button
              size={'xl'}
              mode={'stroke'}
              onClick={() => {
                router.back();
              }}
            >
              이전 페이지로 돌아가기
            </Button>
            <Button
              size={'xl'}
              mode={'filled'}
              onClick={() => {
                const mainUrl = GeneralLinks().find((link) => link.name === 'displaying_list')?.url;
                router.push(mainUrl, mainUrl);
              }}
            >
              메인으로 돌아가기
            </Button>
          </div>
        </div>
      </section>
    </SSLayout>
  );
};

export async function getStaticProps() {
  return {
    props: {},
  };
}

export default Page404;
